import React from "react"
import { css } from "@emotion/core"
import { useStaticQuery, Link, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

//TODO: Actually creat an updates page:

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
            specialty
          }
        }
      }
    `
  )
  return (
    <header style={{ marginBottom: `1.5rem` }}>
      <div
        css={css`
          margin: 0 auto;
          max-width: 1200px;
          padding: ${rhythm(2)};
          padding-top: ${rhythm(1.5)};
        `}
      >
        <Link to={`/`}>
          <h3
            css={css`
              margin-bottom: ${rhythm(2)};
              display: inline-block;
              font-style: normal;
            `}
          >
            {data.site.siteMetadata.name}
          </h3>
        </Link>
        <Link to={`/projects/`}>
          <h3
            css={css`
              margin-bottom: ${rhythm(2)};
              float: right;
              display: inline-block;
              font-style: normal;
            `}
          >
            projects
          </h3>
        </Link>
        {children}
      </div>
    </header>
  )
}
